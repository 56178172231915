<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/security-privacy" parent-url="安全隐私" current-url="云桌面" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/img_7.png" />
          <div class="about">
            <div class="title">云桌面</div>
            <div class="content">
              又称桌面虚拟化、云电脑，是替代传统电脑的一种新模式；采用云桌面后，用户无需再购买电脑主机，主机所包含的CPU、内存、硬盘等组件全部在后端的服务器中虚拟出来，单台高性能服务器可以虚拟1-50台不等的虚拟主机；前端设备主流的是采用瘦客户机（与电视机顶盒类似的设备）连接显示器和键鼠，用户安装客户端后通过特有的通信协议访问后端服务器上的虚拟机主机来实现交互式操作，达到与电脑一致的体验效果。            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">
        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">Critix</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-27.png">
            <div class="company-info">
              Citrix即美国思杰公司，是一家致力于云计算虚拟化、虚拟桌面和远程接入技术领域的高科技企业。现在流行的BYOD（Bring Your Own Device自带设备办公）就是Citrix公司提出的。1997年Citrix确立的发展愿景“让信息的获取就像打电话一样简洁方便，让任何人在任何时间、任何地方都可以随时获取”，这个构想就是今天移动办公的雏形，随着互联网技术的快速发展，通过基于云计算技术的虚拟桌面，人们可以在任何时间、任何地点使用任何设备接入自己的工作环境，在各种不同的场景间无缝切换，使办公无处不在，轻松易行。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">VMware</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-32.png">
            <div class="company-info">
              VMware云计算技术利用VMware混合云和原生公有云为IT组织提供在任意云中运行、管理、连接和保护的应用。凭借私有云、公有云和混合云解决方案，组织实施支持其云计算解决方案，推动业务选择和可控。公有云整合包括VMware混合云和管理多云服务 。VMware混合云产品/服务基于软件定义数据中心体系结构构建，利用VMware的计算、网络连接和存储虚拟化技术 。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">深信服</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-01.png">
            <div class="company-info">
              深信服是一家专注于企业级安全、云计算及IT基础设施的产品和服务供应商，拥有深信服智安全、信服云和深信服新IT三大业务品牌，致力于让每个用户数字化更简单、更安全。
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
